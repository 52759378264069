/* *********** Custom Font ************/

@font-face {
	font-family: 'Montserrat', sans-serif;
	src: url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300&display=swap');
  font-weight: 100;
}
@font-face {
	font-family: 'Montserrat', sans-serif;
	src: url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300&display=swap');
	font-weight: 200;
}
@font-face {
	font-family: 'Montserrat', sans-serif;
	src: url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300&display=swap');
	font-weight: 300;
}


/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
    font-weight: 400;
    overflow-x: hidden;
    position: relative;
    background-color: #F9F7F7 !important;
    color: #000000 !important;
    font-family: 'Centra', sans-serif !important;
  }

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}


p.success {
	color: green;
}

p.danger {
	color: red;
}
/************ Navbar Css ************/
/* Default styles */
nav.navbar {
  padding: 18px 0;
  background-color: #112D4E;
  position: fixed;
  width: 100%;
  z-index: 9999;
  transition:  0.32s ease-in-out;
  display: flex;
  justify-content: center; /* Center the navbar horizontally */
}

nav.navbar.scrolled {
  padding: 0px 0px;
  background-color: #112D4E;
  opacity: 90%;
}

nav.navbar a.navbar-brand {
  width: 15%;
  margin-left: 10%;
  margin-right: 2%;
}

nav.navbar .navbar-nav {
  width: 80%;
  display: flex;
   /* Center the nav links horizontally */
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 300;
  color: #fff !important;
  letter-spacing: 0.8px;
  font-size: 18px;
  display: flex;
  justify-content: left;
  width: 100%;
  padding: 10px 10px 8px 5px;

}

nav.navbar .navbar-nav .drop a{
  font-weight: 300;
  color: #F9F7F7;
  letter-spacing: 0.5px;
  font-size: 18px;
  display: flex;
  justify-content: left;
  padding: 10px 10px 8px 5px; /* Adjusted padding */
}

nav.navbar .navbar-nav .drop .drop-town:hover,
nav.navbar .navbar-nav .drop .drop-town.active {
    opacity: 1;
    background-color: #DBE2EF;

}

nav.navbar .navbar-nav .drop .drop-town {
  font-weight: 300;
  transition: 0.3s ease-in-out;
  color: #112D4E;
}

nav.navbar .navbar-nav .drop .drop-town::before {
  content: "";
  width: 0%;
  height: 100%;
  border-radius: 30px;
  position: absolute;
  background-color: #DBE2EF;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}


.navbar-nav button {
  font-weight: 400;
  color: #F9F7F7;
  border: 1px solid #DBE2EF;
  padding: 18px 20px; /* Adjusted padding */
  font-size: 12px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
  border-radius: 30px;
  margin-left: 5%;
}
.navbar-nav button span {
  z-index: 1;
}
.navbar-nav button::before {
  content: "";
  width: 10%;
  height: 100%;
  border-radius: 30px;
  position: relative;
  background-color: #DBE2EF;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-nav button:hover {
  color: #112D4E;
}
.navbar-nav button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}

.navbar-nav button span {
  z-index: 1;
}


@media only screen and (min-width: 768px) {
  /* Styles for medium devices (tablets) */
  nav.navbar .navbar-nav .nav-link.navbar-link,
  nav.navbar .navbar-nav .drop .drop-town {
    padding: 20px 30px; /* Adjusted padding */
  }
  
  nav.navbar .navbar-nav .drop a {
    padding: 20px 30px; /* Adjusted padding */
  }
  
  .navbar-nav button {
    padding: 18px 30px; /* Adjusted padding */
  }
}

/* Your existing styles for hover effects and toggler */
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}

nav.navbar .navbar-nav .drop .drop-town:hover,
nav.navbar .navbar-nav .drop .drop-town.active {
  opacity: 1;
  background-color: #DBE2EF;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}
/* BANNER */ 
.banner{
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-color:  #F9F7F7;
}

.banner p{
  font-weight: 300;
  color: #4E4637;
  letter-spacing: 0.8px;
  line-height: 1.5rem;
  margin-left: 15%;
  margin-top: 8%;
  width: 70%;
  text-align: justify;
}

.banner .img-szczecin .contact{
  font-weight: 600;
  color: #112D4E;
  text-align: center;
  margin-top: 1%;
}
.banner .img-szczecin{
  flex: 1;
  max-width: 100%;
}

.banner  img {
  width: 450px;
  height: auto;
  margin-top: 5%;
  box-shadow: 5px 5px 10px rgba(18, 18, 18, 0.3);
}

@media only screen and (max-width: 480px) {
  .banner img {
    width: 300px;
  }
}

.banner button {
  font-weight: 300;
  color: #112D4E;
  border: 1px solid #DBE2EF;
  padding: 18px 40px;
	position: relative;
  margin-bottom: 4%;
  margin-top: 1%;
  z-index: 1;
	background-color: #DBE2EF;
	transition: 0.3s ease-in-out;
  border-radius: 30px;
}

.banner button span {
  z-index: 1;
}
.banner button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #112D4E;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
  border-radius: 30px;
}
.banner button:hover {
  color: #fbfbf9;
}
.banner button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}

.about{
  background-color: #112D4E;
  font-weight: 300;
  width: 100%;
  padding: 40px 40px;
}

.about h1{
  font-weight: 200;
  color: #fbfbf9;

}
.about p{
  font-weight: 300;
  color: #fbfbf9;
  font-size: 16px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  text-align: justify;
  width: 100%;
  margin-bottom: 50px;

}

/* APARTMENTS */
.apartments{
  padding: 20px 0 0 0;
  background-color:#F9F7F7;
  display: flex;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}

.apartments p{
  font-weight: 300;
  color: #112D4E;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin-left: 15%;
  text-align: left;
  width: 70%;
}
.apartments .img-szczecin{
  flex: 1;
  margin-bottom: 5%;
  margin-top: 5%;
}

.apartments .skill-slider {
  width: 600px;
  max-width:86%;
  margin-left: 7%;
  margin-top: 4%;
  height: 100%;
  border-radius: 30px;


  /* box-shadow: 5px 5px 10px #112d4e25; */
}
.apartments img {
  width: 76%;
  margin-right: 4%;
  height: auto;
}

.apartments h2{
  font-weight: 200;
  font-size: 40px;
  color: #112D4E;
  padding: 20px;
}
.apartments h3{
  font-weight: 200;
  font-size: 24px;
  color: #112D4E;
}

.apartments h4 {
  font-weight: 600;
  color: #112D4E;
  border-bottom: 2px solid #112D4E;
  margin-bottom: 2%;
  font-size: 20px;
}

.apartments .pointa {
  font-weight: 150;
  font-size: 13px;
  color: #112D4E;
  margin-bottom: 4%;
}

.apartments .point {
  font-weight: 150;
  font-size: 13px;
  color: #112D4E;
}

.apartments .adress {
  font-weight: 150;
  font-size: 13px;
  color: #112D4E;
  text-align: center;
  margin-bottom: 4%;
}

.apartments .img-szczecin .box {
    background-color:#DBE2EF ;
    box-shadow: 5px 5px 10px #112d4e30;
    width:85%;
    margin-left: 7%;
    height: 100%;
    text-align: center;
    padding: 15px;
    border-radius: 30px;
    justify-content: center;
  }

  .apartments .img-szczecin button {
    font-weight: 250;
    text-decoration: none;
    color: #112D4E;
    border: 1px solid #112D4E;
    padding: 16px 30px;
    font-size: 18px;
    position: relative;
    z-index: 9999;
    background-color: transparent;
    transition: 0.3s ease-in-out;
    border-radius: 30px;
  }

  .apartments .img-szczecin button span {
    z-index: 1;
  }

  .apartments .img-szczecin button::before {
    content: "";
    width: 0%;
    height: 100%;
    position: absolute;
    background-color: #112D4E;
    left: 0;
    top: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
    border-radius: 30px;
  }
  .apartments .img-szczecin button:hover {
    color: #fbfbf9;
  }
  .apartments .img-szczecin button:hover::before {
    content: "";
	  width: 100%;
	  height: 100%;
	  position: absolute;
  }

  @media only screen and (max-width: 768px) {
    .apartments p {
      margin-left: 5%;
      width: 90%;
    }
    
    .apartments .img-szczecin .box {
      width: 90%;
      margin-left: 5%;
    }
  }
  @media only screen and (max-width: 480px) {

    .apartments p {
      margin-left: 3%;
      width: 94%;
    }
    
    .apartments .img-szczecin .box {
      width: 94%;
      margin-left: 3%;
    }
  }

/* Photo Section */
.footer {
  background: #112D4E;
  display: flex;
}

.footer .info{
width: 87%;
height: 100%;
margin-left: 5%;
display: inline;
}

/* Image styling */
.footer img {
  margin-top: 2%;
  width: 12%;
  
}

/* Information Section */
.footer .info h1 {
  font-weight: 400;
  margin-top: 2%;
  text-align: center;
  font-size: 28px;
  color: #F9F7F7 !important;
  letter-spacing: 0.5px;
  
}

/* General paragraph styling */
.footer p {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #F9F7F7 !important;
  letter-spacing: 0.5px;
 
}

/* Heading 3 styling */
.footer .info h3 {
  font-weight: 600;
  margin-top: 2%;
  text-align: center;
  font-size: 8px;
  color: #F9F7F7 !important;
  letter-spacing: 0.5px;
}


.App {
  text-align: center;
}
